const colors = {
  base_blue: '#2d388c',
  base_red: '#f71b34',
  base_gold: '#a07846',
  base_silver: '#9da0a2',
  blue_black: '#161b3d',
  blue_medium: '#33375c',
  blue_light: '#8188ba',
  pure_white: '#ffffff',
  grey_medium: '#d8dbde',
  grey_light: '#f8f9f9',
  gold_light: '#f8f5f2',
}

export const theme = {
  // font
  fontPrimary: 'Source Sans Pro, sans-serif',
  fontSecondary: 'GFS Didot, serif',
  // background
  backgroundPrimary: colors.pure_white,
  backgroundSecondary: colors.gold_light,
  backgroundDark: colors.base_blue,
  backgroundLight: colors.grey_light,
  backgroundHighlight: colors.base_gold,
  // text
  textPrimary: colors.blue_black,
  textSecondary: colors.blue_medium,
  textTertiary: colors.grey_medium,
  textHighlight: colors.base_blue,
  textLight: colors.pure_white,
  // button
  buttonPrimary: colors.base_blue,
  buttonLight: colors.pure_white,
  buttonDark: colors.blue_black,
  //border
  borderPrimary: colors.grey_medium,
  borderSecondary: colors.gold_light,
  borderLight: colors.pure_white,
  borderHighlight: colors.base_blue,
}
